import React from 'react';
import Contact from '../components/Contact';
import Logo from '../components/Logo';
import image from "../assets/img.png";
import image1 from "../assets/img_1.png";
import image2 from "../assets/WhatsApp Image 2024-09-13 at 12.28.15_40aeb075.jpg";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const NewPage = () => {

    const { language } = useParams();
    const { t, i18n } = useTranslation('common');

    React.useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);
    return (
        <div className="bg-gray-100 pt-10">
            {/*<Logo />*/}
            <div className="text-center font-bold text-6xl">
                <h1>{t('projects.tag')}</h1>
            </div>
            <div id="first-1" className="relative bg-white overflow-hidden mt-20 border-b-2 border-black-600">
                <div className="max-w-7xl ml-44">
                    <div
                        className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full lg:pb-28 xl:pb-32">

                        <div className="pt-1"></div>

                        <main
                            className="mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div className="sm:text-center lg:text-left">
                                <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                                    {t('projects.list.title-1')}
                                </h2>

                                <p className="text-lg">
                                    {t('projects.info.content-1')}
                                </p>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:w-1/2 lg:right-0" style={{ right: '-12%' }} >
                    <img
                        className="h-56 object-top sm:h-72 md:h-96 lg:h-full w-full sm:w-[63%]"
                        src={image}
                        alt=""
                    />
                </div>
            </div>
            <div id="first-2" className="relative bg-white overflow-hidden mt-20 border-b-2 border-black-600">
                <div className="max-w-7xl ml-44">
                    <div
                        className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full lg:pb-28 xl:pb-32">

                        <div className="pt-1"></div>

                        <main
                            className="mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div className="sm:text-center lg:text-left">
                                <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                                    {t('projects.list.title-2')}
                                </h2>

                                <p className="text-lg">
                                    {t('projects.info.content-2')}
                                </p>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:w-1/2" style={{ right: '-12%' }} >
                    <img className="h-56 object-top sm:h-72 md:h-96 lg:h-full w-full sm:w-[63%]"
                         src={image1} alt="" />
                </div>
            </div>
            <div id="first-3" className="relative bg-white overflow-hidden mt-20 border-b-2 border-black-600">
                <div className="max-w-7xl ml-44">
                    <div
                        className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full lg:pb-28 xl:pb-32">

                        <div className="pt-1"></div>

                        <main
                            className="mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div className="sm:text-center lg:text-left">
                                <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                                    {t('projects.list.title-3')}
                                </h2>

                                <p className="text-lg">
                                    {t('projects.info.content-3')}
                                </p>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:w-1/2" style={{ right: '-12%' }} >
                    <img className="h-56 object-top sm:h-72 md:h-96 lg:h-full w-full sm:w-[63%]"
                         src={image2} alt="" />
                </div>
            </div>
            <Contact />
        </div>
    );
};

export default NewPage;