import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import az from "./translations/az.json";
import en from "./translations/en.json";
import ru from "./translations/ru.json";

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'az',
    resources: {
        en: {
            common: en
        },
        ru: {
            common: ru
        },
        az: {
            common: az
        },
    },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>
);
