import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import i18next from "i18next";

import image from "../assets/img.png";
import image1 from "../assets/img_1.png";
import image2 from "../assets/WhatsApp Image 2024-09-13 at 12.28.15_40aeb075.jpg";

const Projects = () => {
    const { t } = useTranslation("common");

    return (
        <section id='projects' className="bg-white dark:bg-gray-900 min-h-[92vh] flex flex-col">
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-12 lg:pt-16 lg:pb-2">
                {/* Title Section */}
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-2xl sm:text-3xl font-extrabold leading-tight text-gray-900 dark:text-white">
                        {t('projects.tag')}
                    </h2>
                </div>

                <div className="grid grid-cols-1 mt-8 sm:mt-12 gap-x-6 gap-y-8 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="space-y-4">
                        <img
                            src={image}
                            alt="project 1"
                            className="mx-auto w-full h-[200px] object-cover rounded-lg shadow-md 3xl:h-[400px] 3xl:object-fill"
                        />
                        <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
                            {t('projects.list.title-1')}
                        </h3>
                        <p className="text-base sm:text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t('projects.list.content-1')}
                        </p>
                    </div>

                    <div className="space-y-4">
                        <img
                            src={image1}
                            alt="project 2"
                            className="mx-auto w-full h-[200px] object-cover rounded-lg shadow-md 3xl:h-[400px] 3xl:object-fill"
                        />
                        <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
                            {t('projects.list.title-2')}
                        </h3>
                        <p className="text-base sm:text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t('projects.list.content-2')}
                        </p>
                    </div>

                    <div className="space-y-4">
                        <img
                            src={image2}
                            alt="project 3"
                            className="mx-auto w-full h-[200px] object-unset rounded-lg shadow-md 3xl:h-[400px] 3xl:object-fill"
                        />
                        <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
                            {t('projects.list.title-3')}
                        </h3>
                        <p className="text-base sm:text-lg font-normal text-gray-500 dark:text-gray-400">
                            {t('projects.list.content-3')}
                        </p>
                    </div>
                </div>

                <div className="mt-20 flex justify-center">
                    <Link
                        to={`/projects/${i18next.language}`}
                        title=""
                        target="_blank"
                        className="text-white bg-primary-700 hover:bg-primary-800 inline-flex items-center
                                   focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium
                                   rounded-lg text-base px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700
                                   dark:focus:ring-primary-800"
                        role="button"
                    >
                        {t('projects.list.detail')}
                        <svg aria-hidden="true" className="w-5 h-5 ml-2 -mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Projects;
